<template>
  <v-list-item
    class="notification-item"
    :class="!notification.read ? 'bg-yellow' : 'transparent'"
    @click="clickHandler"
  >
    <v-list-item-avatar>
      <img :src="notification.image" alt="" />
    </v-list-item-avatar>

    <!-- <v-list-item-content>
    </v-list-item-content> -->
    <div class="content">
      <p v-html="notiText"></p>
    </div>
    <v-list-item-action-text>
      {{ notiTime }}
    </v-list-item-action-text>
  </v-list-item>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("notification");
export default {
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    notiText() {
      const text = this.notification.text;
      if (text.length > 80) return text.substring(0, 80) + "...";
      return text;
    },
    notiTime() {
      const time = this.notification.time;
      if (time.includes("seconds"))
        return time.replace("seconds", "s ago").replace("in ", "");
      else if (time.includes("minutes"))
        return time.replace("minutes", "m ago").replace("in ", "");
      else if (time.includes("minute"))
        return time.replace("minute", "m ago").replace("in ", "");
      else if (time.includes("hours"))
        return time.replace("hours", "h ago").replace("in ", "");
      else if (time.includes("months"))
        return time.replace("months", "mo ago").replace("in ", "");
      else if (time.includes("month"))
        return time.replace("month", "mo ago").replace("in ", "");
      else return time.replace("in ", "");
    },
  },
  methods: {
    ...mapActions(["READ_SINGLE_NOTIFICATION"]),
    clickHandler() {
      const { link, id } = this.notification;
      this.READ_SINGLE_NOTIFICATION(id);
      let path = link;
      if (path === "/") path = `/notification/${id}`;
      this.$router.push(path);
    },
  },
};
</script>

<style lang="scss" scoped>
.notification-item {
  .content {
    align-items: center;
    align-self: center;
    display: flex;
    flex: 1 1;

    p {
      font-size: 12px;
      line-height: 15px;
      color: #000;
      margin-bottom: 0;
      padding-right: 0.9rem;
    }
  }
  .v-list-item__action {
    font-size: 12px;
    line-height: 15px;
    color: #000000;
    opacity: 0.5;
  }
}
</style>
