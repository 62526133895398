<template>
  <AppPage>
    <template #app-bar>
      <v-toolbar-title>{{
        $vuetify.lang.t("$vuetify.notifications")
      }}</v-toolbar-title>
      <v-spacer></v-spacer>

      <v-menu
        bottom
        origin="center center"
        transition="scale-transition"
        elevation="1"
      >
        <template v-slot:activator="{ on, attrs }">
          <span class="primary--text edit-text " v-bind="attrs" v-on="on">
            {{ $vuetify.lang.t("$vuetify.edit") }}
          </span>
        </template>

        <v-list>
          <v-list-item @click="MARK_ALL_READ">
            <v-list-item-title>{{
              $vuetify.lang.t("$vuetify.mark_all_read")
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>

    <!-- <div class="mt-5" v-for="(notification, name, index) in {}" :key="index">
      <p class="heading">{{ name }}</p> -->

    <v-list v-if="!loading.all_notifications">
      <NotificationBar
        v-for="noti in notification_list"
        :key="noti.id"
        :notification="noti"
      />

      <p class="text-center subtitle-1" v-if="!notification_list.length">
        {{ $vuetify.lang.t("$vuetify.no_notification_for_now") }}
      </p>
    </v-list>

    <div v-else>
      <v-skeleton-loader
        type="list-item-avatar"
        v-for="n in 5"
        :key="n"
      ></v-skeleton-loader>
    </div>

    <!-- </div> -->

    <div class="light-bg" v-intersect="infiniteScrolling"></div>
  </AppPage>
</template>

<script>
import NotificationBar from "@/components/notification/notification-bar";

import { createNamespacedHelpers } from "vuex";
const notiModule = createNamespacedHelpers("notification");
export default {
  name: "Notification",
  components: { NotificationBar },
  data() {
    return {};
  },
  computed: {
    ...notiModule.mapState(["loading", "hasNext", "page", "notifications"]),
    ...notiModule.mapGetters(["notification_list"]),
  },
  methods: {
    ...notiModule.mapActions(["FETCH_NOTIFICATIONS", "MARK_ALL_READ"]),
    ...notiModule.mapMutations(["CLEAR_ALL_NOTI_DATA"]),
    infiniteScrolling(entries, observer, isIntersecting) {
      if (this.hasNext && !this.loading.all_notifications && isIntersecting) {
        this.FETCH_NOTIFICATIONS();
      }
    },
  },
  created() {},
  beforeDestroy() {
    this.CLEAR_ALL_NOTI_DATA();
  },
};
</script>

<style lang="scss" scoped>
p.heading {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  text-transform: capitalize;
  padding: 0 1rem;
  margin-bottom: 0;
}

.edit-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}
</style>
